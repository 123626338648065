import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const AboutPageTemplate = ({
  title,
  content,
  contentComponent,
  headerImage
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section">
      {headerImage && headerImage.image && (
        <Img
          onContextMenu="return false;"
          style={{}}
          fluid={headerImage.image.childImageSharp.fluid}
          alt={headerImage.alt}
        />
      )}
      <div className="container mx-auto max-w-4xl">
        <div className="my-10 text-lg">
          <h1 className="text-3xl tracking-widest mb-3 text-gray-900 uppercase">
            {title}
          </h1>
          <PageContent
            className="content font-light text-lg text-gray-900 leading-relaxed"
            content={content}
          />
        </div>
      </div>
    </section>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  headerImage: PropTypes.shape({}),
  contentComponent: PropTypes.func
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <>
        <AboutPageTemplate
          contentComponent={HTMLContent}
          title={post.frontmatter.title}
          headerImage={post.frontmatter.headerImage}
          content={post.html}
        />
      </>
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        headerImage {
          image {
            childImageSharp {
              fluid(maxWidth: 1240, quality: 74) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
  }
`;
